import useClientMutation from '../../common/useClientMutation';
import { ALERTS_UNSUBSCRIBE_URL } from '../../consts';

import type {
  UserAlertsRegistrationOrUnsubscribeInput,
  UserAlertsRegistrationOrUnsubscribeOutput,
} from '@haaretz/s-data-structure-types';

type TagAlertsRegistrationInit =
  | {
      onSuccess?: (data: UserAlertsRegistrationOrUnsubscribeOutput) => void;
      onError?: (data: Error) => void;
    }
  | undefined;

export default function useUserAlertsUnsubscribe(props?: TagAlertsRegistrationInit) {
  return useClientMutation<
    UserAlertsRegistrationOrUnsubscribeOutput,
    UserAlertsRegistrationOrUnsubscribeInput
  >({
    cacheKey: 'useUserAlertsUnsubscribe',
    url: ALERTS_UNSUBSCRIBE_URL,
    fetchOptions: { method: 'GET' },
    clientOptions: {
      useErrorBoundary: false,
      cacheTime: 0,
      retry: false,
      onSuccess: props?.onSuccess,
      onError: props?.onError,
    },
  });
}
